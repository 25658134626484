let loadedImages = 0
const maxImagesAtOnce = 3

export default {
  inserted (el, binding) {
    if (!binding.value || loadedImages >= maxImagesAtOnce) {
      return
    }

    const loadImage = () => {
      el.src = binding.value
      el.removeAttribute('data-src')
      loadedImages++

      el.onload = () => {
        loadedImages = Math.max(0, loadedImages - 1)
      }

      el.onerror = () => {
        loadedImages = Math.max(0, loadedImages - 1)
      }
    }

    if (!('IntersectionObserver' in window)) {
      loadImage()
      return
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && loadedImages < maxImagesAtOnce) {
          loadImage()
          observer.unobserve(el)
        }
      })
    }, { rootMargin: '200px', threshold: 0.1 })

    observer.observe(el)
  }
}
