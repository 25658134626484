class StoreService {
  constructor () {
    if (StoreService.instance) {
      return StoreService.instance
    }

    try {
      if (process.server) {
        this.locale = process?.env?.WWW_HOST?.includes('ru') ? 'ru' : 'en'
      } else {
        this.locale = window?.location?.href?.includes('ru') ? 'ru' : 'en'
      }
    } catch (e) {
      console.log(e)
    }

    StoreService.instance = this

    return this
  }

  getProducts (engine) {
    return engine.get('/products')
  }

  getGameCurrenciesBrands (engine) {
    return engine.get('/marketplace/v-brands')
  }

  getGameCurrencies (engine, brand) {
    const params = {
      brand
    }

    return engine.get('/marketplace/virtual', { params })
  }

  getMarketplaceProducts (engine, page, size, category, brand) {
    const params = {
      page,
      size,
      locale: this.locale
    }

    if (brand !== '') {
      params.brand = brand
    }

    if (category !== '') {
      params.category = category
    }

    return engine.get('/marketplace', { params })
  }

  getMarketplaceProductsWithUserID (engine, page, size, categoryId, bestsellers = false, categories = null, app = null, sub = null, orderBy = undefined, orderByDirection = undefined) {
    const params = {
      page,
      size,
      locale: this.locale
    }

    if (categoryId !== '') {
      params.category_id = categoryId
    }

    if (bestsellers) {
      params.bestseller = bestsellers
    }

    if (categories?.length) {
      params.categories = categories
    }

    if (app) {
      params.app = app
    }

    if (sub && sub !== '') {
      params.sub = sub
    }

    if (orderBy) {
      params.order_by = orderBy
      params.order_by_direction = orderByDirection || 'asc'
    }

    return engine.get('/marketplace/with-uid', { params })
  }

  getMarketplaceProductsForGame (engine, page, size, gameName) {
    const params = {
      page,
      size,
      locale: this.locale,
      game_name: gameName
    }

    return engine.get('/marketplace/for-game', { params })
  }

  getMarketplaceProductsToModerate (engine) {
    return engine.get('/marketplace/not-moderated')
  }

  moderateProduct (engine, id) {
    return engine.post(`/marketplace/${id}/moderate`)
  }

  deleteProduct (engine, id) {
    return engine.post(`/marketplace/${id}/delete`)
  }

  recoverProduct (engine, id) {
    return engine.post(`/marketplace/${id}/recover`)
  }

  getMarketplaceProductsBySellerID (engine, page, size, sellerId, onSale, appId = undefined, activeServiceType = undefined) {
    const params = {
      page,
      size,
      locale: this.locale,
      on_sale: onSale
    }

    if (appId && appId !== '') {
      params.app_id = appId
    } else if (activeServiceType && activeServiceType !== '') {
      params.app_type = activeServiceType
    }

    return engine.get(`/marketplace/by-user/${sellerId}`, { params })
  }

  getMarketplaceFavouriteProducts (engine, page, size) {
    const params = {
      page,
      size,
      locale: this.locale
    }

    return engine.get('/marketplace/favourites', { params })
  }

  getMarketplaceApps (engine) {
    const params = {
      locale: this.locale
    }

    return engine.get('/marketplace/apps', { params })
  }

  getMarketplaceCounters (engine) {
    const params = {
      locale: this.locale
    }

    return engine.get('/marketplace/apps/categories-counters', { params })
  }

  getMarketplaceApp (engine, appId) {
    const params = {
      locale: this.locale
    }

    return engine.get(`/marketplace/apps/${appId}`, { params })
  }

  getMarketplaceAppByPath (engine, path) {
    const params = {
      locale: this.locale
    }

    return engine.get(`/marketplace/apps/by-path/${path}`, { params })
  }

  offerNewMarketplaceAppCategory (engine, appType, name) {
    return engine.post('/marketplace/app/offer-new', { app_type: appType, name })
  }

  addMarketplaceApp (engine, app) {
    return engine.post('/marketplace/app/add', {
      item: app,
      locale: this.locale
    })
  }

  updateMarketplaceApp (engine, app) {
    return engine.post(`/marketplace/app/${app.id}/update`, {
      item: app,
      locale: this.locale
    })
  }

  addMarketplaceAppCategory (engine, appId, category) {
    return engine.post(`/marketplace/app/${appId}/category/add`, {
      item: category,
      locale: this.locale
    })
  }

  updateMarketplaceAppCategory (engine, appId, category) {
    return engine.post(`/marketplace/app/${appId}/category/update`, {
      item: category,
      locale: this.locale
    })
  }

  addMarketplaceProduct (engine, item) {
    return engine.post('/marketplace/add', {
      item,
      locale: this.locale
    })
  }

  updateMarketplaceProduct (engine, id, item, options) {
    return engine.post(`/marketplace/${id}/update`, { item, options })
  }

  getCart (engine) {
    return engine.get('/marketplace/cart')
  }

  addToCart (engine, itemId, coinsToUse) {
    return engine.post('/marketplace/cart/add', {
      item: {
        item_id: itemId,
        coins: coinsToUse
      }
    })
  }

  addToFavourites (engine, itemId) {
    return engine.post('/marketplace/favourites/add', {
      item: {
        product_id: itemId
      }
    })
  }

  dropFavourite (engine, itemId) {
    return engine.post('/marketplace/favourites/drop', {
      item: {
        product_id: itemId
      }
    })
  }

  clearFavourites (engine) {
    return engine.post('/marketplace/favourites/clear')
  }

  dropFromCart (engine, itemId) {
    return engine.post('/marketplace/cart/drop', {
      item: {
        item_id: itemId
      }
    })
  }

  purchase (engine, productId, data) {
    return engine.post(`/products/${productId}/purchase`, data)
  }

  purchaseWithCard (engine, productId, isProlong, isRu = false, gameId = 0) {
    let url = '/payments/providers/unlimint/recurring'
    let body = {
      productID: productId,
      gameId,
      return_url: `${location.protocol}//${location.host}/profile/subscription`,
      currency: 'EUR',
      autoProlong: isProlong,
      autoProlongBalance: false
    }

    if (isRu) {
      url = '/payments/providers/contactpay/recurring'
      body = {
        productID: productId,
        gameId,
        return_url: `${location.protocol}//${location.host}/profile/subscription`,
        currency: 'EUR',
        payway: 'card_acquiring_rub'
      }
    }

    return engine.post(url, body)
  }

  getMarketplace (engine, productId) {
    return engine.get(`/marketplace/${productId}`)
  }

  getMarketplaceOrders (engine) {
    return engine.get('/marketplace/orders')
  }

  sendYConversion (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/y-conversion-sent`)
  }

  orderSeen (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/seen`)
  }

  getMarketplaceOrdersAsSeller (engine) {
    return engine.get('/marketplace/seller/orders')
  }

  createOrder (engine, customerData, link) {
    return engine.post('/marketplace/order', { order_data: customerData, returning_url: link })
  }

  createVirtualOrder (engine, customerData, link, phone) {
    return engine.post('/marketplace/v-order', { order_data: customerData, returning_url: link, phone })
  }

  payForOrder (engine, orderId, method, url, phone) {
    return engine.post(`/marketplace/v-order/${orderId}/pay`, {
      method,
      url,
      phone
    })
  }

  completeVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/complete`)
  }

  fulfillVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/prepare`)
  }

  progressVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/progress`)
  }

  cancelVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/cancel`)
  }

  buyerCancelVirtualOrder (engine, orderId) {
    return engine.post(`/marketplace/v-order/${orderId}/cancel-after-payment`)
  }

  getCanRefund (engine, productId) {
    return engine.get(`/user/products/${productId}/refund`)
  }

  refund (engine, productId) {
    return engine.post(`/user/products/${productId}/refund`)
  }

  cancel (engine, productId) {
    return engine.post(`/user/products/${productId}/cancellation`)
  }

  sendReview (engine, orderId, stars, comment) {
    return engine.post(`/marketplace/v-order/${orderId}/review`, {
      stars,
      comment
    })
  }

  getReviewStatsBySellerId (engine, sellerId) {
    return engine.get(`/marketplace/reviews-stats/${sellerId}`)
  }

  getReviewsBySellerId (engine, sellerId, page, size) {
    const params = {
      page,
      size
    }
    return engine.get(`/marketplace/reviews/${sellerId}`, { params })
  }
}

export default new StoreService()
