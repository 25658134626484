<template lang="pug">
footer.footer
  .content
    .footer__vertical__group
      .footer__section.footer__section--1
        .footer__logo-box
          img.footer__logo(
            :src='require("~/assets/img/VVV-logo.svg")',
            alt='logo'
          )
          span.footer__logo-text Gaming-Goods
        .footer__contacts-wrap
          SocialLinks
          a.footer__email(href='mailto:tech.support@gaming-goods.com') tech.support@gaming-goods.com

      .footer__section.footer__section--1
        .footer__logo-box
          span.footer__gplay--text {{ $t('overwolfDownload') }}
        a(href="https://play.google.com/store/apps/details?id=com.vvvgamers")
          img.footer__gplay(
            src='~/assets/img/gplay.webp',
            alt='Google Play App'
            loading='lazy'
            width='120'
            height='40'
          )

      .footer__section.footer__section--3
        p.footer__copyright.
          © Gaming-Goods 2020 - {{ currentYear }}
          <br/>
          {{ $t('copyright') }}.
        .footer__payment-systems
          //img.footer__payment-logo(src='~/assets/img/payment-systems/paypal.svg' alt="paypal")
          img.footer__payment-logo(
            v-if="!isRu"
            src="~/assets/img/payment-systems/visa.svg",
            alt='visa'
            loading='lazy'
          )
          img.footer__payment-logo(
            v-if="!isRu"
            src="~/assets/img/payment-systems/mastercard.svg"
            alt="mastercard"
            loading="lazy"
          )
          img.footer__payment-logo(
            v-if="!isRu"
            src="~/assets/img/payment-systems/visa-secure.svg"
            alt="visa secure"
            loading="lazy"
          )
          img.footer__payment-logo(
            v-if="!isRu"
            src="~/assets/img/payment-systems/mc-secure.svg"
            alt="mastercard idcheck"
            loading="lazy"
          )
          img.footer__payment-logo(
            v-if="isRu"
            src="~/assets/img/payment-systems/mir.svg"
            alt="mir"
            loading="lazy"
          )

    .footer__vertical__group
      .footer__section.footer__section--2
        div.footer__links
          a.footer__link(
            href='#',
            v-support-button
          )
            span {{ $t("supportCenter") }}

          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/user-agreement")')
            span {{ $t("rulesAndUserAgreement") }}
          a.footer__link(
            v-else
            :href='userAgreementLink',
            target='_blank',
            rel='noopener noreferrer'
          )
            span {{ $t("rulesAndUserAgreement") }}

          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/withdrawal-policy")')
            span {{ $t("withdrawalPolicy") }}
          a.footer__link(
            v-else
            :href='withdrawalPolicyLink',
            target='_blank',
            rel='noopener noreferrer'
          )
            span {{ $t("withdrawalPolicy") }}

          nuxt-link.footer__link(:to='localePath("/info/about")')
            span {{ $t("aboutUs") }}
          nuxt-link.footer__link(:to='localePath("/become-advertiser")')
            span {{ $t("partners") }}

          nuxt-link.footer__link(
            :to="localeLocation(privacyPolicyLink)"
          )
            span {{ $t("privacyPolicy") }}

          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/cookie-policy")')
            span {{ $t("cookiePolicy") }}
          a.footer__link(
            v-else
            :href='cookiePolicyLink'
            target='_blank'
            rel='noopener noreferrer'
          )
            span {{ $t("cookiePolicy") }}

          nuxt-link.footer__link(:to='localePath("/info/contacts")')
            span {{ $t("contacts") }}

          nuxt-link.footer__link.footer__age(:to='localePath("/info/age-restrictions")')
            span {{ $t("ageRestrictions") }}

          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/risk-disclosure")')
            span {{ $t("riskDisclosure") }}
          a.footer__link(
            v-else
            :href='riskDisclosureLink',
            target='_blank',
            rel='noopener noreferrer'
          )
            span {{ $t("riskDisclosure") }}

          .footer__link.footer__link--desktop
          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/kyc-aml")')
            span {{ $t("KYCAMLPolicy") }}
          a.footer__link(
            v-else
            :href='KYCAMLPolicyLink',
            target='_blank',
            rel='noopener noreferrer'
          )
            span {{ $t('KYCAMLPolicy') }}

          nuxt-link.footer__link(v-if="isRu" :to='localePath("/info/restricted-jurisdictions")')
            span {{ $t("nonAcceptableJurisdictions") }}
          a.footer__link(
            v-else
            :href='jurisdictionsLink',
            target='_blank',
            rel='noopener noreferrer'
          )
            span {{ $t('nonAcceptableJurisdictions') }}

          nuxt-link.footer__link(:to='localePath("/marketplace/all")')
            span {{ $t("marketplaceAllPageH1") }}

          .footer__link.footer__link--desktop
          .footer__link.footer__link--desktop

      //noindex(v-pre)
      .footer__section.footer__section--4
        .footer__text--not-sponsored
          object(type='image/svg+xml' data='/info.svg' width='20' height='20' :title='$t("information")') I
          span(v-html="$t('footerBusinessText')")
        p.footer__text.footer__text--first(v-if="isRu")
          | Solian Enterprises Limited. Регистрационный номер: HE 412444. адрес Emmanouil road 44, Kirzis Center, 3031 Limassol, Cyprus. Налоговый номер: 10412444G.
          span
            span(style='white-space: nowrap') Телефон:&nbsp;
            a(href='tel:+35780080579') +357 800 80579
        p.footer__text.footer__text--first(v-else)
          | Solian Enterprises Limited. Registered number: HE 412444.  Emmanouil road 44,  KIRZIS CENTER, 3031 Limassol, Cyprus. VAT: 10412444G.&nbsp;
          span
            span(style='white-space: nowrap') Phone number:&nbsp;
            a(href='tel:+35780080579') +357 800 80579
        p.footer__text.footer__text--second(v-if="!isRu") All included here mentioned brand names are registered and property of the respective companies. World of Warcraft tm and Blizzard Entertainment are registered trademarks of Blizzard Entertainment Inc.<br/><br/>PUBG and PUBG Mobile are registered trademarks and are in no way affiliated with Gaming-Goods
</template>

<script>
import SocialLinks from '~/components/SocialLinks/SocialLinks'

export default {
  name: 'Footer',

  components: { SocialLinks },

  computed: {
    isRu () {
      return this.$i18n.locale === 'ru'
    },

    userAgreementLink () {
      return this.$i18n.locale === 'ru'
        ? 'https://drive.google.com/file/d/1_G5b7ok78miqeWU2uIuxtn9ngdDUTqde/view?usp=sharing'
        // : 'https://drive.google.com/file/d/1NamZ03xwWAgYGPyH6ZH0olvsrn9Lrwlf/view?usp=sharing' // +
        : 'https://gaming-goods.com/terms_and_conditions'
    },

    withdrawalPolicyLink () {
      return this.$i18n.locale === 'ru'
        ? 'https://drive.google.com/file/d/1OuE5XU9o1Zr3dYqQmeHkgnabIYk49bMI/view?usp=sharing'
        : 'https://drive.google.com/file/d/1iN8I-FU_knWBcZ-gdhX486NyE4wTKWnQ/view?usp=sharing' // +
    },

    jurisdictionsLink () {
      return this.$i18n.locale === 'ru'
        ? 'https://drive.google.com/drive/folders/1OnLYAm9J6EkG3bTXFvZ_W6lJnM52TDWh?usp=sharing'
        : 'https://drive.google.com/file/d/1ex84SD4Z2ktJOt4hwf8j55efXle5lYpY/view?usp=sharing'
    },

    KYCAMLPolicyLink () {
      return this.$i18n.locale === 'ru'
        ? 'https://drive.google.com/file/d/1yUDyw9Gqbf2Q1thDAi3rRI0NfteuB_UI/view?usp=sharing'
        : 'https://drive.google.com/file/d/1BYgKmqdyUgOYVTd-Oset0jvvArclo9_z/view?usp=sharing' // +
    },

    riskDisclosureLink () {
      return this.$i18n.locale === 'ru'
        ? 'https://drive.google.com/file/d/1fmYraMAoz6Yzp5EzDOgbOAC-EgNB0Jza/view?usp=sharing'
        : 'https://drive.google.com/file/d/1MTs2ar6BoE2ZlLHbFQQibW8DH68LGkgi/view?usp=sharing' // +
    },

    cookiePolicyLink () {
      return this.$i18n.locale === 'ru'
        ? this.localeLocation('/info/cookie-policy')
        : 'https://drive.google.com/file/d/1h6OB59TOWeGJ7Q_6FHW8zNuJ5iLEmBf1/view?usp=sharing' // +
    },

    privacyPolicyLink () {
      // return this.$i18n.locale === 'ru'
      // ? 'https://drive.google.com/file/d/1yEucMPIlBvLKJYZ_c_yNb3NcffewgrNX/view?usp=sharing'
      // : 'https://drive.google.com/file/d/1xqsaHc4mufYdy1jYsJwqDK6XeD_eKvY2/view?usp=sharing' // +
      return '/privacy-policy'
    },

    currentYear () {
      return new Date().getFullYear()
    }
  },

  methods: {
    localiseLink (link, ext = 'pdf') {
      ext = '.' + ext

      return link.replace(
        ext,
        this.$i18n.locale === 'ru' ? `_ru${ext}` : `_en${ext}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 64px;
  padding: 32px;
  border-top: 1px solid $blue-grey40;
  background: $blue-grey90;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 2347px;
    height: 2347px;
    left: -1218px;
    top: -1119px;
    background:
      radial-gradient(
        50% 50% at 50% 50%,
        #9bb0cb 0%,
        rgb(207 216 220 / 0%) 100%
      );
    opacity: 0.1;
    overflow: hidden;
    pointer-events: none;
  }

  .content {
    width: 1435px;
    display: grid;
    column-gap: 5%;
    grid-template-columns: 290px auto;
    margin: auto;

    @media (max-width: 1500px) {
      width: auto;
      display: block;
    }
  }

  &__age {
    position: relative;

    &::after {
      content: "18+";
      display: flex;
      padding: 2px 4px;
      border-radius: 3px;
      align-items: center;
      border: 1px solid $white20;
      justify-content: center;
      position: absolute;
      top: 0;
      left: -37px;
      font-size: 80%;
      font-weight: bold;
      width: fit-content;
    }
  }

  &__first-section {
    display: flex;
    flex-direction: column;
    width: 308px;
    margin-right: 80px;
  }

  &__btn {
    display: block;
    max-width: 227px;
    text-align: center;
    padding: 0;
    font-weight: $font-medium;
  }

  &__contacts-wrap {
    @include flex-center;

    width: 100%;
    flex-wrap: wrap;
    margin-top: 32px;
    text-align: center;

    @media (min-width: $breakpointDesktop) {
      text-align: left;
      justify-content: inherit;
    }
  }

  &__email {
    @include heading6;

    color: $blue-grey05;
    width: 100%;
  }

  &__copyright {
    @include text-display100;

    color: $blue-grey05;
    line-height: 23px;
    width: 260px;
    font-weight: 500;
  }

  &__payment-systems {
    display: flex;
    justify-content: space-between;
    margin: 32px 0 12px;

    img {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__second-section {
    display: flex;
    flex-direction: column;
    max-width: 740px;
    padding-top: 90px;
  }

  &__logo-box {
    display: flex;
    align-items: center;

    svg path {
      fill: $blue-grey05;
    }
  }

  &__gplay {
    margin: 12px 0;

    &--text {
      color: $blue-grey05;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__logo-text {
    justify-self: flex-end;
    font-size: 19px;
    font-weight: $font-medium;
    color: $blue-grey05;
    padding-left: 5px;
  }

  &__links {
    @include heading6;

    color: $blue-grey05;
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__link {
    width: fit-content;

    &:hover,
    &:focus {
      color: #1f87e5;
    }

    &--desktop {
      display: none;

      @media (min-width: $breakpointDesktop) {
        display: block;
      }
    }
  }

  &__text {
    @include text-display100;

    color: #8299b5;
    font-size: 16px;
    line-height: 18px;
  }

  &__text--second {
    margin-top: 20px;
  }
}

.footer__vertical__group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $breakpointTablet) {
    flex-direction: row;
    gap: 12px;
  }

  @media (min-width: 1499px) {
    flex-direction: column;
    gap: 0;
  }
}

.footer .social-links {
  margin: 0 0 16px;
}

.footer__section {
  display: flex;
  flex-direction: column;
}

.footer__section--1 {
  width: fit-content;
  margin-bottom: 40px;
}

.footer__section--2 {
  width: 100%;
  justify-content: flex-end;
}

.footer__section--3 {
  width: fit-content;
}

.footer__section--4 {
  width: 100%;
}

.footer__text--not-sponsored {
  display: flex;
  align-items: center;
  color: $blue-grey10;
  margin-bottom: 16px;

  &:first-child {
    margin-top: 24px;
  }

  span {
    padding-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 800px) {
  .footer {
    display: grid;
    column-gap: 5%;
    grid-template-columns: 100%;
    padding: 48px 24px;
  }

  .footer__section {
    width: 100%;
    align-items: center;
  }

  .footer .social-links {
    width: fit-content;
    margin: 0 24px 24px 0;
  }

  .footer__links {
    grid-template-columns: 1fr 1fr;
  }

  .footer__copyright {
    width: 100%;
    text-align: center;
  }

  .footer__section--3 {
    padding-bottom: 27px;
    padding-top: 0;
    border-bottom: 1px solid $blue-grey40;
  }

  .footer__text--second {
    margin-top: 27px;
  }

  .footer__section--1 {
    border-bottom: 1px solid $blue-grey40;
    margin-bottom: 36px;
    padding-bottom: 36px;
  }

  .footer__section--2 {
    margin: 24px 0;
    padding: 33px 0;
    border-bottom: 1px solid $blue-grey40;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 32px 16px;
  }

  .footer__section {
    width: 100%;
    align-items: flex-start;
  }

  .footer__copyright {
    text-align: left;
  }

  .footer__links {
    justify-content: flex-start;
  }

  .footer__contacts-wrap {
    justify-content: flex-start;
  }
}

@media (max-width: $breakpointMobile) {
  .footer__email {
    text-align: left;
  }

  .footer__age {
    width: min-content;
  }

  .footer__section--2 {
    padding: 0 0 24px;
  }

  .footer__section--4 {
    padding-top: 0;
  }

  .footer__text--not-sponsored {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

@media (max-width: $breakpointDesktop) {
  .footer__section--2 {
    margin: 24px 0;
  }

  .footer__contacts-wrap {
    display: block;
  }
}

@media (max-width: $breakpointTablet) {
  .footer__contacts-wrap {
    display: flex;
  }
}
</style>
