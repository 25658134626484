<template lang="pug">
.modal-offer-details
  //.i-flocktory(data-fl-action="track-item-view" :data-fl-item-id="offer.id" :data-fl-item-category_id="offer.category" :data-fl-item-vendor="offer.source")
  .modal-offer-details__head
    img.modal-offer-details__cover(:src="offer.cover" alt="cover")
    .modal-offer-details__payouts
      // .modal-offer-details__payout
        span.modal-offer-details__payout-amount +{{ payoutCoins }}
        img(:src="require('~/assets/img/icons/coins-payout.svg')" alt="coins")
      .modal-offer-details__payout
        span.modal-offer-details__payout-amount +{{ offerPayout }}
        span.modal-offer-details__payout-currency €
    .modal-offer-details__devices(:style="{'--count': devices.length}")
      .modal-offer-details__device(v-for="device in devices" :key="device")
        img(:src="require(`~/assets/img/icons/devices/${device}.svg`)" alt="device")
    .modal-offer-details__name {{ offer.name }}
  .modal-offer-details__description(v-html="offerDescription")

  .modal-offer-details__categories
    .modal-offer-details__category
      .modal-offer-details__category-label(v-t='"category"')
      .modal-offer-details__category-name(v-t='offerCategory')
    .modal-offer-details__category
      .modal-offer-details__category-label(v-t='"offerStatus"')
      .modal-offer-details__category-name(v-t='offerStatus')
    .modal-offer-details__category
      .modal-offer-details__category-label(v-t='"popularity"')
      .modal-offer-details__category-name(v-t='offer.popularity')
    .modal-offer-details__category
      .modal-offer-details__category-label(v-t='"offerProvider"')
      .modal-offer-details__category-name(v-t='offer.source')

  template(v-if="userCanStartTheOffer")
    a.modal-offer-details__start-container(:href="offerLink" target="_blank")
      Button.modal-offer-details__start(is-primary @click="startOffer") {{ $t('start') }}
  template(v-else-if="isAuthorized")
    .modal-offer-details__start-container
      Button.modal-offer-details__bad-geo(is-primary @click="loadCountry")
        | {{ $t('badGeo') }}
        img(:src="require('~/assets/img/icons/refresh.svg')" alt="refresh")
  template(v-else)
    .modal-offer-details__start-container
      Button.modal-offer-details__sign-up(is-primary @click="openAuthModal") {{ $t('signUpAction') }}
  .modal-offer-details__footer
    span
    .modal-offer-details__support
      img.modal-offer-details__support-icon(v-support-button :src="require('~/assets/img/icons/mail-support.svg')" :alt="$t('userSupportCenter')")
      span.modal-offer-details__support-text {{ $t('techSupport') }}
</template>

<script>
import OfferwallService from '@/services/OfferwallService'
import { mapActions, mapGetters, mapState } from 'vuex'
import { create24MetricsScript } from '~/utils/24metrics'
import { postUrl } from '~/utils/utils'

export default {
  name: 'ModalOfferDetails',

  props: {
    offer: {
      type: Object,
      required: true
    },

    startCountryCode: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      offerStatus: 'New',
      realCountryCode: undefined,
      step1: false,
      fingerprint: undefined,
      uuid: undefined,
      clickDateTime: (+new Date()).toString()
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAuthorized', 'isAdmin']),

    payoutCoins () {
      return (+this.offer?.payout * 1000).toFixed(0)
    },

    offerPayout () {
      return this.offer?.payout.toFixed(2).replace('.00', '')
    },

    offerCategory () {
      return this.offer?.categories[this.offer?.categories.length - 1]
    },

    offerSource () {
      return OfferwallService.getOfferwallGroup(this.$axios, this.user?.id)?.find(item => item.title === this.offer?.source)?.name || this.offer?.source?.toLowerCase()
    },

    devices () {
      if (this.offer?.device === '') {
        return [
          'android',
          'ios',
          'windows',
          'mac'
        ]
      }

      return [this.offer?.device]
    },

    offerLink () {
      if (this.isAuthorized) {
        if (this.$cookiz.get(`offer_${this.offer?.external_id}_step_0`) || this.step1) {
          return this.offer?.offer_url.split(' ')[1].replace('[USER_ID]', this.user?.id)
        }
        return this.offer?.offer_url?.split(' ')[0]
          .replace('[USER_ID]', this.user?.id)
          .replace('[USER_ID]', this.user?.id)
          .replace('[TIMESTAMP]', this.clickDateTime)
          .replace('[EMAIL]', this.user?.email)
      }
      return ''
    },

    offerDescription () {
      return this.offer?.description
        ?.replace('[USER_ID]', '<b>' + this.user?.id + '</b>')
        ?.replace('[EMAIL]', '<b>' + this.user?.email + '</b>')
    },

    userCanStartTheOffer () {
      return this.isAuthorized && this.offer.countries.includes(this.realCountryCode)
    }
  },

  async mounted () {
    this.realCountryCode = this.startCountryCode
    this.checkOfferStatus()
    await this.loadCountry()
    this.createScript()
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    createScript () {
      const callback = (fingerprint, uuid) => {
        this.fingerprint = fingerprint
        this.uuid = uuid
      }

      create24MetricsScript(this.$el, this.$cookiz, this.$axios, { id: this.offer.id, source: this.offerSource }, this.user?.id, 'open-offer-modal', `${this.user?.id}_${this.clickDateTime}`, callback)
    },

    openLeaderboard () {
      this.$router.push(this.localeLocation('/leaderboard'))
    },

    openCheckModal () {
      if (!this.isAuthorized) {
        this.openAuthModal()
      } else {
        this.showModal({
          component: 'ModalQualityCheck'
        })
      }
    },

    startOffer () {
      if (!this.user?.id) {
        return
      }

      this.$gtag.event('start_offerwall',
        {
          userid: this.user?.id,
          offer_id: this.offer?.id,
          offerwall_category: this.offerCategory,
          offerwall_status: 'New',
          offerwall_popularity: this.offer?.popularity,
          offerwall_provider: this.offer?.brand,
          offerwall_name: this.offer?.name,
          offerwall_device: this.offer?.device,
          offerwall_reward_euro: (this.offer?.payout).toFixed(2)
        })
      // this.$gtag.event(`start_offer_${this.offer?.id}_${this.offer?.external_id}`, { userid: this.user?.id })
      try {
        postUrl(`KochavaTracker://sendEventString?nameString=start_offer_${this.offer?.id}_${this.offer?.external_id}`)
      } catch (e) {
        console.log(e)
      }
      if (this.offer?.offer_url.split(' ').length > 1) {
        setTimeout(() => {
          this.$cookiz.set(`offer_${this.offer?.external_id}_step_0`, 1)
          this.step1 = true
          this.offerStatus = 'In Progress'
        }, 3000)
      }
    },

    async loadCountry () {
      try {
        this.realCountryCode = await OfferwallService.getCountry(this.$axios)
      } catch (e) {
        console.log(e)
      }
    },

    async checkOfferStatus () {
      this.offerStatus = await OfferwallService.checkStatus(this.$axios, this.offer.source, this.offer.external_id)
      if (this.$cookiz.get(`offer_${this.offer?.external_id}_step_0`) || this.step1) {
        this.offerStatus = 'In Progress'
      }
    },

    openAuthModal () {
      this.showModal({
        component: 'Auth',
        data: {
          mode: 'register'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-offer-details {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: $breakpointTablet) {
    padding: 30px;
  }

  &__head {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 150px 1fr 120px;
      grid-template-rows: 1fr max-content;
      grid-gap: 30px;
      align-items: start;
      margin-bottom: 56px;
    }
  }

  &__cover {
    --size: 165px;
    height: var(--size);
    width: var(--size);
    margin: auto;
    object-fit: contain;

    @media (min-width: $breakpointTablet) {
      --size: 150px;
      grid-row: 1 / 3;
    }
  }

  &__payouts {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
    justify-content: center;

    @media (min-width: $breakpointTablet) {
      grid-row: 1 / 3;
      grid-column: 3 / 4;
      width: 100%;
      grid-template-columns: 1fr;
      margin-top: 54px;
    }
  }

  &__payout {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-width: 90px;
    border: 1px solid #E38C25;
    border-radius: 10px;

    &:first-child {
      border: 1px solid $fc-grey-2;
    }

    &-amount {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: $white;
      margin-right: 5px;
    }

    &-currency {
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: #E38C25;
    }
  }

  &__devices {
    display: grid;
    grid-template-columns: repeat(var(--count), 34px);
    grid-gap: 10px;
    margin: auto;

    @media (min-width: $breakpointTablet) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      margin: 0;
    }
  }

  &__device {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 1px solid $fc-grey-2;
    border-radius: 10px;
    background-color: $blue-grey80;
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: $white;
    margin-top: 11px;
    margin-bottom: 18px;

    @media (min-width: $breakpointTablet) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      margin: 0;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    margin-bottom: 18px;
    overflow: auto;
    max-width: 100%;

    @media (min-width: $breakpointTablet) {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 42px;
    }
  }

  &__categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 7px 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: max-content max-content max-content 1fr;
    }
  }

  &__category {
    &:nth-child(2n) {
      .modal-offer-details__category-label {
        text-align: right;

        @media (min-width: $breakpointTablet) {
          text-align: center;
        }
      }
    }

    &-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 28px;
      color: $fc-grey-2;

      @media (min-width: $breakpointTablet) {
        text-align: center;
      }
    }

    &-name {
      width: 100%;
      padding: 1px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      background-color: $blue-grey80;
      border: 1px solid $blue-grey50;
      border-radius: 10px;
      color: $white;

      @media (min-width: $breakpointTablet) {
        min-width: 130px;
      }
    }
  }

  &__start {
    width: 100%;

    &-container {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__bad-geo {
    width: 100%;
    background: $fc-grey-2;
  }

  &__sign-up {
    width: 100%;
    background: #5968BE;
  }

  &__leaderboard {
    width: 100%;
    background: linear-gradient(90deg, #e52a46 0%, #8f2431 100%);
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__support {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
    align-items: center;

    @media (min-width: $breakpointTablet) {
      grid-template-columns: 1fr 50px;
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: $fc-grey-2;

      @media (min-width: $breakpointTablet) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        text-align: end;
      }
    }

    &-icon {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.modal.modal--modalofferdetails {
  min-width: 300px;
  max-width: 640px !important;
  padding: 0 !important;
}
</style>
