<template lang="pug">
  button.button(
    :class="classModifiers"
    v-bind="$attrs"
    :disabled="isDisabled"
    :type="$attrs.type || 'button'"
    @click="onClick"
  )
    .button__body(v-if="isSquare")
      img(v-if="icon && icon.type === 'png'" :src="require(`~/assets/img/icons/png/${icon.name}.png`)" :alt="icon.name")
      img.icon24(v-else-if="icon" :src="require(`~/assets/img/icons/${icon.name || icon }.svg`)" width="24" height="24" alt="icon")
      slot

    .button__body(v-else-if="onlyIcon" :class="{'--reverse': reverse}")
      .button__icon.button__icon--only(v-if="icon")
        img(v-if="icon.type === 'png'" :src="require(`~/assets/img/icons/png/${icon.name}.png`)" :alt="icon.name")
        img.icon24(v-else :src="require(`~/assets/img/icons/${icon.name || icon }.svg`)" alt="icon")

    .button__body(v-else :class="{'--reverse': reverse}")
      .button__icon(v-if="icon")
        img(v-if="icon.type === 'png'" :src="require(`~/assets/img/icons/png/${icon.name}.png`)" :alt="icon.name")
        img.icon24(v-else :src="require(`~/assets/img/icons/${icon.name || icon }.svg`)" alt="icon")
      span.button__text(v-if="$slots.default")
        slot
      .button__icon-right(v-if="isShop")
        img.icon24(:src="require(`~/assets/img/icons/shop.svg`)" alt="shop")

</template>

<script>
export default {
  name: 'Button',

  props: {
    isSecondary: {
      type: Boolean,
      default: false
    },

    isSquare: {
      type: Boolean,
      default: false
    },

    isPrimary: {
      type: Boolean,
      default: false
    },

    isPrimaryTransparent: {
      type: Boolean,
      default: false
    },

    isGreen: {
      type: Boolean,
      default: false
    },

    isBordered: {
      type: Boolean,
      default: false
    },

    isWhite: {
      type: Boolean,
      default: false
    },

    isBlue: {
      type: Boolean,
      default: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isActive: {
      type: Boolean,
      default: false
    },

    isSmall: {
      type: Boolean,
      default: false
    },

    isShop: {
      type: Boolean,
      default: false
    },

    isPurple: {
      type: Boolean,
      default: false
    },

    isRed: {
      type: Boolean,
      default: false
    },

    icon: {
      type: [String, Object],
      default: null
    },

    reverse: {
      type: Boolean,
      default: false
    },

    onlyIcon: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    classModifiers () {
      return {
        'button--secondary': this.isSecondary,
        'button--primary': this.isPrimary,
        'button--primary-transparent': this.isPrimaryTransparent,
        'button--square': this.isSquare,
        'button--bordered': this.isBordered,
        'button--white': this.isWhite,
        'button--blue': this.isBlue,
        'button--disabled': this.isDisabled,
        'button--active': this.isActive,
        'button--small': this.isSmall,
        'button--shop': this.isShop,
        'button--purple': this.isPurple,
        'button--green': this.isGreen,
        'button--red': this.isRed
      }
    }
  },

  methods: {
    onClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped lang="scss">
.--reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
}
.icon24 {
  width: 24px;
  height: 24px;
}
</style>

<style lang="scss">
button.button {
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  padding: 0;

  .button__icon {
    img {
      max-height: 24px;
      max-width: 24px;
    }
  }

  .button__text {
    display: flex;
    align-items: center;
  }

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--small {
    &.button--primary,
    &.button--primary-transparent,
    &.button--secondary {
      padding: 8px 16px;
    }
  }

  &--shop {
    .button__body {
      display: flex;
      align-items: center;
    }

    .button--primary {
      .button__text {
        line-height: 24px;
      }
    }

    .button--primary-transparent {
      .button__text {
        line-height: 24px;
      }
    }

    .button__icon-right {
      margin-left: 8px;
    }
  }

  &--square {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    outline: none;
    border-radius: 8px;
    background: $blue-grey60;
    transition: 0.2s $easing;
    cursor: pointer;

    &.button--active {
      background: $blue-grey40;

      svg,
      path,
      rect {
        fill: $primary60;
      }
    }

    .button__body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--secondary {
    padding: 9px 16px;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-width: fit-content;
    border-radius: 8px;
    background: $blue-grey60;
    transition: 0.2s $easing;

    &.button--active {
      background: $primary60;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
      }

      &__icon {
        margin-right: 8px;
        display: flex;
        align-items: center;

        &--only {
          margin: 0;
        }
      }

      &__text {
        @include button;

        color: $white;
      }
    }
  }

  &--primary {
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: $gradient-primary;
    border-radius: 8px;
    transition: 0.2s $easing;
    position: relative;
    height: 48px;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      z-index: 0;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #1565c0 0%, #1e88e5 100%);
      border-radius: 8px;
      opacity: 0;
      transition: 0.2s $easing;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;
      }

      &__text {
        @include button;

        color: $white;
      }

      &__icon {
        margin-right: 8px;
        position: relative;
        top: -1px;
        z-index: 0;

        &--only {
          margin: 0;
        }
      }
    }

    &:hover {
      &::before {
        opacity: .7;
      }
    }
  }

  &--primary-transparent {
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: $primary60-20;
    border-radius: 8px;
    transition: 0.2s $easing;
    position: relative;
    height: 48px;
    color: $primary60;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      z-index: 0;
      height: 100%;
      left: 0;
      top: 0;
      background: $primary60-20;
      border-radius: 8px;
      opacity: 0;
      transition: 0.2s $easing;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;
      }

      &__text {
        @include button;

        color: $primary60;
      }

      &__icon {
        margin-right: 8px;
        position: relative;
        top: -1px;
        z-index: 0;

        &--only {
          margin: 0;
        }
      }
    }

    &:hover {
      &::before {
        opacity: .7;
      }
    }
  }

  &--green {
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: $green60;
    border-radius: 8px;
    transition: 0.2s $easing;
    position: relative;
    height: 48px;
    color: $white;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      z-index: 0;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, $green60 0%, $green20 100%);
      border-radius: 8px;
      opacity: 0;
      transition: 0.2s $easing;
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
        position: relative;
      }

      &__text {
        @include button;

        color: $white;
      }

      &__icon {
        margin-right: 8px;
        position: relative;
        top: -1px;
        z-index: 0;

        &--only {
          margin: 0;
        }
      }
    }

    &:hover {
      &::before {
        opacity: .7;
      }
    }
  }

  &--purple {
    background: $indigo60;
  }

  &--red {
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background: linear-gradient(90deg, #c6265c 0%, #e81f62 100%);
    border-radius: 8px;
    transition: 0.2s $easing;
    position: relative;
    height: 48px;

    &:hover {
      background: $gradient-red !important;
    }

    .button {
      &__text {
        @include button;

        color: $white;
      }
    }
  }

  &--bordered {
    border: 2px solid $primary60;
    border-radius: 8px;
    padding: 0 24px;
    background: transparent;
    transition: background 0.2s $easing;
    box-sizing: border-box;
    height: 48px;

    &.button--white {
      border-color: white;
    }

    &.button--blue {
      border-color: $primary60;

      .button__text {
        color: $primary60;
      }
    }

    .button {
      &__body {
        height: 24px;
        display: flex;
        align-items: center;
      }

      &__icon {
        margin-right: 8px;
        display: flex;

        &--only {
          margin: 0;
        }
      }

      &__text {
        @include button;

        color: $white;
        transition: color 0.2s $easing;
      }
    }

    &:hover {
      background: $gradient-primary;
      background: $primary60;

      /* border-color: transparent; */

      &.button--white {
        background: white;

        .button__text {
          color: $blue-grey90;
        }

        svg > * {
          fill: $blue-grey90;
        }
      }

      &.button--blue {
        background: $primary60;

        .button__text {
          color: white;
        }

        svg > * {
          fill: white;
        }
      }
    }
  }

  &.button--secondary,
  &.button-square:not(.button--active) {
    &:hover {
      background: $blue-grey40;
    }

    &:active {
      background: $blue-grey40;
    }
  }
}
</style>
