class PaymentService {
  constructor () {
    if (PaymentService.instance) {
      return PaymentService.instance
    }

    this.methods = [
      // {
      //   id: 'ru-card',
      //   methodName: 'PAY2PLAY',
      //   title: 'ruBankCard',
      //   payIn: false,
      //   payOut: true,
      //   provider: 'pay2play',
      //   adminOnly: false,
      //   ruOnly: true,
      //   commission: 8,
      //   fixCommission: 0,
      //   commissionPayOut: 2.5,
      //   fixCommissionPayOut: 0,
      //   minPayIn: 21,
      //   minPayOut: 55,
      //   icons: [
      //     {
      //       alt: 'visa',
      //       src: require('~/assets/img/payment-systems/visa.svg')
      //     },
      //     {
      //       alt: 'mastercard',
      //       src: require('~/assets/img/payment-systems/mastercard.svg')
      //     },
      //     {
      //       alt: 'mir',
      //       src: require('~/assets/img/payment-systems/mir.svg')
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'RU',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/ru-card.svg'
      //   }
      // },
      // {
      //   id: 'ru-card',
      //   methodName: 'PAY2PLAY',
      //   title: 'ruBankCard',
      //   payIn: false,
      //   payOut: true,
      //   provider: 'pay2play',
      //   adminOnly: false,
      //   ruOnly: true,
      //   commission: 8,
      //   fixCommission: 0,
      //   commissionPayOut: 5.5,
      //   fixCommissionPayOut: 0,
      //   minPayIn: 1,
      //   minPayOut: 31,
      //   icons: [
      //     {
      //       alt: 'visa',
      //       src: require('~/assets/img/payment-systems/visa.svg')
      //     },
      //     {
      //       alt: 'mastercard',
      //       src: require('~/assets/img/payment-systems/mastercard.svg')
      //     },
      //     {
      //       alt: 'mir',
      //       src: require('~/assets/img/payment-systems/mir.svg')
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'RU',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/ru-card.svg'
      //   }
      // },
      // {
      //   id: 'ru-card',
      //   methodName: 'CONTACTPAY',
      //   title: 'ruBankCard',
      //   payIn: true,
      //   payOut: false,
      //   provider: 'contactpay',
      //   adminOnly: false,
      //   ruOnly: true,
      //   commission: 4,
      //   fixCommission: 0,
      //   commissionPayOut: 2.5,
      //   fixCommissionPayOut: 0,
      //   minPayIn: 1,
      //   minPayOut: 55,
      //   icons: [
      //     {
      //       alt: 'mir',
      //       src: require('~/assets/img/payment-systems/mir.svg')
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'RU',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/ru-card.svg'
      //   }
      // },
      {
        id: 'ru-card-paymentlnk',
        methodName: 'PAYMENTLNK',
        title: 'ruBankCard',
        payIn: true,
        payOut: true,
        provider: 'paymentlnk',
        adminOnly: false,
        ruOnly: true,
        commission: 6,
        fixCommission: 0,
        commissionPayOut: 2,
        fixCommissionPayOut: 0,
        minPayIn: 1,
        minPayOut: 11,
        icons: [
          {
            alt: 'mir',
            src: require('~/assets/img/payment-systems/mir.svg')
          }
        ],
        mainIcon: {
          alt: 'RU',
          src: 'https://cdn.gaming-goods.com/images/Payment%20methods/ru-card.svg'
        }
      },
      {
        id: 'credit-card-paymentlnk',
        methodName: 'PAYMENTLNK_EU',
        title: 'creditCardVisa',
        payIn: true,
        payOut: true,
        provider: 'paymentlnk',
        adminOnly: false,
        euOnly: true,
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3.5,
        fixCommissionPayOut: 1.5,
        minPayIn: 10,
        minPayOut: 10,
        icons: [
          {
            alt: 'visa',
            src: require('~/assets/img/payment-systems/visa.svg')
          },
          {
            alt: 'mastercard',
            src: require('~/assets/img/payment-systems/mastercard.svg')
          }
        ],
        mainIcon: {
          alt: 'card',
          src: 'https://cdn.gaming-goods.com/images/Payment%20methods/usd-card.svg'
        }
      },
      {
        id: 'ru-sbp-paymentlnk',
        methodName: 'PAYMENTLNK',
        title: 'ruSBP',
        payIn: true,
        payOut: false,
        provider: 'paymentlnk',
        adminOnly: true,
        ruOnly: true,
        commission: 5,
        fixCommission: 0,
        commissionPayOut: 2,
        fixCommissionPayOut: 0,
        minPayIn: 1,
        minPayOut: 1,
        icons: [
          {
            alt: 'mir',
            src: require('~/assets/img/payment-systems/mir.svg')
          }
        ],
        mainIcon: {
          alt: 'RU',
          src: 'https://cdn.gaming-goods.com/images/Payment%20methods/ru-card.svg'
        }
      },
      // {
      //   id: 'credit-card',
      //   methodName: 'BANKCARD',
      //   title: 'creditCardVisa',
      //   payIn: true,
      //   payOut: true,
      //   commission: 3.3,
      //   fixCommission: 0.25,
      //   commissionPayOut: 1.5,
      //   fixCommissionPayOut: 1,
      //   minPayIn: 1,
      //   minPayOut: 4.65,
      //   euOnly: true,
      //   icons: [
      //     {
      //       alt: 'visa',
      //       src: require('~/assets/img/payment-systems/visa.svg')
      //     },
      //     {
      //       alt: 'mastercard',
      //       src: require('~/assets/img/payment-systems/mastercard.svg')
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'card',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/usd-card.svg'
      //   }
      // },
      {
        id: 'google-pay',
        methodName: 'GOOGLEPAY',
        title: 'Google Pay',
        payIn: false,
        payOut: false,
        commission: 7,
        fixCommission: 0,
        euOnly: true,
        icons: [
          {
            alt: 'google pay',
            src: require('~/assets/img/payment-systems/google-pay.svg')
          },
          {
            alt: 'visa',
            src: require('~/assets/img/payment-systems/visa.svg')
          },
          {
            alt: 'mastercard',
            src: require('~/assets/img/payment-systems/mastercard.svg')
          }
        ]
      },
      {
        id: 'apple-pay',
        methodName: 'APPLEPAY',
        title: 'Apple Pay',
        payIn: true,
        commission: 7,
        fixCommission: 0.25,
        euOnly: true,
        icons: [
          {
            alt: 'apple pay',
            src: require('~/assets/img/payment-systems/apple-pay.svg')
          }
        ]
      },
      {
        id: 'tether',
        methodName: 'USDT.TRC20',
        title: 'Tether USD (Tron/TRC20)',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'ltc',
            src: require('~/assets/img/payment-systems/usdt.svg')
          }
        ]
      },
      {
        id: 'ltc',
        methodName: 'LTC',
        title: 'Litecoin - LTC (Litecoin)',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'ltc',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/LTC.svg'
          }
        ]
      },
      {
        id: 'bch',
        methodName: 'BCH',
        title: 'Bitcoin Cash - BCH (Bitcoin Cash)',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'bch',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/BCH.svg'
          }
        ]
      },
      {
        id: 'busd',
        methodName: 'BUSD',
        title: 'BUSD - Ethereum (ERC20) / BNB Smart Chain (BEP20)',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'busd',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/BUSD.svg'
          }
        ]
      },
      {
        id: 'dash',
        methodName: 'DASH',
        title: 'Dash ',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'dash',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/DASH.svg'
          }
        ]
      },
      {
        id: 'doge',
        methodName: 'DOGE',
        title: 'Dogecoin - DOGE (dogecoin)',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'doge',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/DOGE.svg'
          }
        ]
      },
      {
        id: 'waves',
        methodName: 'WAVES',
        title: 'WAVES - Waves',
        payIn: true,
        payOut: false,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 3,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 2,
        euOnly: true,
        icons: [
          {
            alt: 'waves',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/WAVES.svg'
          }
        ]
      },
      {
        id: 'crypto',
        methodName: 'CRYPTO',
        title: 'cryptocurrenciesOption',
        payIn: false,
        payOut: true,
        provider: 'coinpayments',
        commission: 0.5,
        fixCommission: 0,
        commissionPayOut: 2,
        fixCommissionPayOut: 0,
        minPayIn: 2,
        minPayOut: 5,
        // euOnly: true,
        icons: [
          {
            alt: 'cryptocurrency',
            src: 'https://cdn.gaming-goods.com/images/Payment%20methods/V.svg'
          }
        ],
        mainIcon: {
          alt: 'RU',
          src: 'https://cdn.gaming-goods.com/images/Payment%20methods/V.svg'
        },
        coins: [
          {
            id: 'ltc',
            methodName: 'LTC',
            title: 'Litecoin - LTC (Litecoin)',
            icon: 'https://cdn.gaming-goods.com/images/Payment%20methods/LTC.svg',
            commission: 0.5,
            fixCommission: 0,
            commissionPayOut: 2,
            fixCommissionPayOut: 0,
            minPayIn: 2,
            minPayOut: 5
          },
          {
            id: 'usdc',
            methodName: 'USDC',
            title: 'USDC (SOLANA)',
            icon: 'https://cdn.gaming-goods.com/images/Payment%20methods/USDC.svg',
            commission: 0.5,
            fixCommission: 0,
            commissionPayOut: 2,
            fixCommissionPayOut: 0,
            minPayIn: 10,
            minPayOut: 5,
            adminOnly: false
          },
          {
            id: 'usdt',
            methodName: 'USDT',
            title: 'USDT (TRC20)',
            icon: 'https://cdn.gaming-goods.com/images/Payment%20methods/USDC.svg',
            commission: 0.5,
            fixCommission: 0,
            commissionPayOut: 0.5,
            fixCommissionPayOut: 3,
            minPayIn: 10,
            minPayOut: 10,
            adminOnly: true
          }
        ]
      }
      // {
      //   id: 'ru-sbp',
      //   methodName: 'PAY2PLAY_SBP', // 'YOURPAYMENT_SBP',
      //   title: 'ruSBP',
      //   payIn: false,
      //   payOut: true,
      //   provider: 'pay2play', // 'yourpayment',
      //   adminOnly: false,
      //   ruOnly: true,
      //   commission: 5,
      //   fixCommission: 0,
      //   commissionPayOut: 5.5,
      //   fixCommissionPayOut: 0,
      //   minPayIn: 1,
      //   minPayOut: 11,
      //   icons: [
      //     {
      //       alt: 'SBP',
      //       src: 'https://cdn.gaming-goods.com/images/Payment%20methods/sbp.svg'
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'SBP',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/sbp.svg'
      //   }
      // },
      //
      // {
      //   id: 'ru-sbp',
      //   methodName: 'CONTACTPAY',
      //   title: 'ruSBP',
      //   payIn: true,
      //   payOut: false,
      //   provider: 'contactpay', // 'yourpayment',
      //   adminOnly: false,
      //   ruOnly: true,
      //   commission: 6.5,
      //   fixCommission: 0,
      //   commissionPayOut: 6.5,
      //   fixCommissionPayOut: 0,
      //   minPayIn: 1,
      //   minPayOut: 11,
      //   icons: [
      //     {
      //       alt: 'SBP',
      //       src: 'https://cdn.gaming-goods.com/images/Payment%20methods/sbp.svg'
      //     }
      //   ],
      //   mainIcon: {
      //     alt: 'SBP',
      //     src: 'https://cdn.gaming-goods.com/images/Payment%20methods/sbp.svg'
      //   }
      // }
      // {
      //   id: 'usdt',
      //   methodName: 'USDT',
      //   title: 'USDT',
      //   payIn: true,
      //   payOut: false,
      //   commission: 0,
      //   fixCommission: 0,
      //   icons: [
      //     {
      //       alt: 'usdt',
      //       src: require('~/assets/img/payment-systems/usdt.svg'),
      //     },
      //   ],
      // },
    ]
    PaymentService.instance = this

    return this
  }

  getPaymentMethods (mode = 'payIn', withGooglePay = false) {
    const filters = []

    if (mode === 'payOut') {
      filters.push(method => method.payOut)
    } else if (mode === 'payIn') {
      filters.push(method => method.payIn)
    }

    if (!withGooglePay) {
      filters.push(method => method.id !== 'google-pay')
    }

    let result = this.methods

    for (const filter of filters) {
      result = result.filter(filter)
    }

    return result
  }

  getMethod (id) {
    return this.methods.find(method => method.id === id)
  }

  getPayInLink (engine, body) {
    return engine.post('/payments/providers/unlimint/pay-in', body)
  }

  getCoinPaymentsData (engine, body) {
    return engine.post('/payments/providers/coinpayments/pay-in', body)
  }

  coinpaymentsPayOut (engine, body) {
    return engine.post('/payments/providers/coinpayments/pay-out', body)
  }

  getPayOutLink (engine, body) {
    return engine.post('/payments/providers/unlimint/pay-out', body)
  }

  getSolidGateSignature (engine, body) {
    return engine.post('/payments/providers/solidgate/pay-in', body)
  }

  getTransactionInfo (engine, transactionId) {
    return engine.get(`/payments/${transactionId}`)
  }

  getUsdtWallet (engine) {
    return engine.get('/payments/providers/usdt')
  }

  payWithUsdt (engine, amount) {
    return engine.post('/payments/providers/usdt/pay-in', { amount })
  }

  payWithEnot (engine, amount) {
    return engine.post('/payments/providers/enot/pay-in', { amount })
  }

  payWithContactpay (engine, amount, phone, debug = false) {
    const payway = phone ? 'sbp_rub' : 'card_acquiring_rub' // 'card_core_payin_rub[mock_server,success_with_callback]'
    const data = { amount, payway }
    if (phone) {
      data.phone = phone.replace('+', '')
    }
    if (debug) {
      alert(`pay data ${data}`)
    }
    return engine.post('/payments/providers/contactpay/payin', data)
  }

  payWithPaymentlnk (engine, amount, returnUrl) {
    const currency = returnUrl?.includes('ru.') ? 'RUB' : 'EUR'

    const data = {
      amount,
      return_url: returnUrl
    }

    return engine.post(`/payments/providers/${currency === 'RUB' ? 'paymentlnk' : 'paymentlnk_eu'}/payin`, data)
  }

  payWithPaymentlnkSBP (engine, amount, returnUrl) {
    const data = {
      amount,
      return_url: returnUrl
    }

    return engine.post('/payments/providers/paymentlnk/sbp/payin', data)
  }

  payWithPay2Play (engine, amount) {
    const data = { amount }
    return engine.post('/payments/providers/pay2play/payin', data)
  }

  payOutWithContactpay (engine, amount, phone, card) {
    const payway = card ? 'qiwi_topup_card_rub' : 'qiwi_topup_wallet_rub'
    const account = card || phone.replace('+', '')
    const data = { amount, payway, account }
    if (card) {
      data.phone = phone.replace('+', '')
    }
    return engine.post('/payments/providers/contactpay/payout', data)
  }

  payOutWithYourPayment (engine, amount, card) {
    const data = { amount, card }
    return engine.post('/payments/providers/yourpayment/payout', data)
  }

  payOutWithPaymentlnk (engine, amount, card, currency = 'RUB') {
    const data = {
      amount,
      card,
      currency
    }
    return engine.post('/payments/providers/paymentlnk/payout', data)
  }

  payOutWithYourPaymentSBP (engine, amount, phone, bank) {
    const data = {
      amount,
      card: phone,
      bank
    }
    return engine.post('/payments/providers/yourpayment/payout', data)
  }

  payOutWithPay2Play (engine, amount, card) {
    const data = { amount, card }
    return engine.post('/payments/providers/pay2play/payout', data)
  }

  payOutWithPay2PlaySBP (engine, amount, sbp) {
    const data = { amount, sbp }
    return engine.post('/payments/providers/pay2play/payout', data)
  }

  getEnotLink (amount, transactionId, sign, qiwi = false) {
    return `https://${qiwi ? 'oplata.to' : 'enot.io'}/pay/?m=47613&oa=${amount}&o=${transactionId}&s=${sign}${qiwi ? '&p=qw' : ''}`
  }

  getGooglePayConfig (engine) {
    return engine.get('/payments/providers/solidgate/pay-in/google-pay')
  }

  getGooglePay3DSUrl (engine, body) {
    return engine.post('/payments/providers/solidgate/pay-in/google-pay', body)
  }

  getApplePaySession (engine, url) {
    return engine.get('/payments/providers/unlimint/apple-pay/session', {
      params: { url }
    })
  }

  getBitcoinRate (engine) {
    return engine.get('/platform/currencies').then((response) => {
      const { data } = response.data
      if (data.length) {
        const btcRate = data.find(item => item.code === 'BTC')?.exchange_rate
        return btcRate
      }
      return 0
    })
  }
}

export default new PaymentService()
