<script>
import StoreService from '@/services/StoreService'
import FormInput from '@/components/FormInput/FormInput.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ModalNewCategory',

  components: {
    FormInput
  },

  props: {
    serviceType: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      activeServiceType: this.serviceType || 'game',
      categoryTitle: ''
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    setActiveServiceType (value) {
      this.activeServiceType = value
    },

    selectorVariantStyles (type) {
      return {
        [this.$style.selector__variant]: true,
        [this.$style.selector__variantActive]: type === this.activeServiceType
      }
    },

    roundStyles (type) {
      return {
        [this.$style.selector__iconRound]: true,
        [this.$style.selector__iconRoundActive]: type === this.activeServiceType
      }
    },

    async createCategory () {
      await StoreService.offerNewMarketplaceAppCategory(this.$axios, this.activeServiceType, this.categoryTitle)

      this.showModal({
        component: 'ModalMessage',
        data: {
          title: this.$t('success'),
          type: 'info',
          text: this.$t('yourDataWasSend')
        }
      })
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <div class="modal__header">
      <p :class="$style.title">
        {{ $t("offerCategoryModalTitle") }}
      </p>
    </div>

    <div :class="$style.body">
      <p :class="$style.text">
        {{ $t("offerCategoryModalText") }}
      </p>

      <div :class="$style.selector">
        <div :class="selectorVariantStyles('game')" @click="setActiveServiceType('game')">
          <div :class="$style.selector__icon">
            <div :class="roundStyles('game')">
              <div v-if="activeServiceType === 'game'" :class="$style.selector__iconDot"></div>
            </div>
          </div>
          {{ $t("games") }}
        </div>
        <div :class="selectorVariantStyles('mobile_game')" @click="setActiveServiceType('mobile_game')">
          <div :class="$style.selector__icon">
            <div :class="roundStyles('mobile_game')">
              <div v-if="activeServiceType === 'mobile_game'" :class="$style.selector__iconDot"></div>
            </div>
          </div>
          {{ $t("servicesInMGame") }}
        </div>
        <div :class="selectorVariantStyles('app')" @click="setActiveServiceType('app')">
          <div :class="$style.selector__icon">
            <div :class="roundStyles('app')">
              <div v-if="activeServiceType === 'app'" :class="$style.selector__iconDot"></div>
            </div>
          </div>
          {{ $t("offer_type_app_downloads") }}
        </div>
        <div :class="selectorVariantStyles('social')" @click="setActiveServiceType('social')">
          <div :class="$style.selector__icon">
            <div :class="roundStyles('social')">
              <div v-if="activeServiceType === 'social'" :class="$style.selector__iconDot"></div>
            </div>
          </div>
          {{ $t("socialNetworks") }}
        </div>
      </div>

      <p :class="$style.label">
        {{ $t('title') }}
      </p>

      <FormInput v-model="categoryTitle" />
    </div>

    <div class="modal__footer">
      <Button is-primary :class="$style.button" @click="createCategory">
        {{ $t('buttonSend') }}
      </Button>
    </div>
  </div>
</template>

<style>
.modal--modalnewcategory {
  width: 100%;
  max-width: 600px !important;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  max-width: 80%;
}

.text {
  color: $white;
  margin: 0 0 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body {
  padding: 40px 0;
}

.label {
  margin: 24px 0 12px;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.button {
  width: 280px;
  margin: 24px 0 0;
}

.selector {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (min-width: $breakpointTablet) {
    grid-template-columns: 1fr 1fr;
  }

  &__variant {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $blue-grey50;
    border: 2px solid $blue-grey50;
    border-radius: 12px;
    padding: 16px;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;

    &Active {
      border: 2px solid $primary60;
      cursor: auto;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-right: 8px;

    &Round {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid $blue-grey20;

      &Active {
        border: 2px solid $primary60;
      }
    }

    &Dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $primary60;
    }
  }
}
</style>
