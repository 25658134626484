class ChatService {
  constructor () {
    if (ChatService.instance) {
      return ChatService.instance
    }

    this.baseUrl = 'https://chat.gaming-goods.com/chat'

    ChatService.instance = this

    return this
  }

  getChatMessages (engine, chatId, after) {
    if (after) {
      return engine.get(`${this.baseUrl}/${chatId}?last=${after}`)
    }
    return engine.get(`${this.baseUrl}/${chatId}`)
  }

  getAllChats (engine, accountId) {
    const url = `${this.baseUrl}/account/${accountId}`
    return engine.get(url)
  }

  readMessage (engine, accountId, chatId, lastMessageId) {
    return engine.post(`${this.baseUrl}/${chatId}/read`, {
      last_message_id: lastMessageId,
      account_id: accountId
    })
  }

  sendMessage (engine, chatId, message) {
    return engine.post(`${this.baseUrl}/${chatId}/send`, message)
  }

  sendNotification (engine, accountId, orderId, productId) {
    console.log(accountId, orderId, productId)
    return engine.post('/marketplace/chat/notifications', {
      account_id: accountId,
      order_id: orderId,
      product_id: productId
    })
  }

  getNotReceivedMessages (engine, accountId) {
    return engine.get(`${this.baseUrl}/account/${accountId}/new`)
  }
}

export default new ChatService()
