<script>
import Modal from '@/components/Modal/Modal'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'LayoutReader',

  components: {
    Modal
  },

  computed: {
    ...mapState('modal', {
      isModalActive: 'isActive',
      isModalVisible: 'isVisible',
      canClose: 'canClose'
    })
  },

  mounted () {
    this.fetchCanBeWithdrawn()
  },

  methods: {
    ...mapMutations('modal', { toggleModal: 'toggle' }),
    ...mapActions('user', ['fetchCanBeWithdrawn']),

    onOverlayClick () {
      if (this.canClose) {
        this.toggleModal()
      }
    }
  }
}
</script>

<template>
  <div :class="$style.layout">
    <Nuxt />

    <transition name="modal-fade-scale" duration="200">
      <div v-if="isModalActive" :class="$style.layout__modalContainer">
        <div v-if="isModalActive" :class="$style.layout__overlay" @click="onOverlayClick"></div>
        <Modal v-show="isModalVisible" />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" module>
.layout {
  @include silent-scroll;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: fill-available;

  @media (min-width: $breakpointTablet) {
    @include scroll;
    overflow-x: hidden;
  }

  &__overlay {
    background-color: rgb(0 0 0 / 66%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    cursor: pointer;
  }

  &__modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 998;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
