import { render, staticRenderFns } from "./EditProductModal.vue?vue&type=template&id=4ca17249"
import script from "./EditProductModal.vue?vue&type=script&lang=js"
export * from "./EditProductModal.vue?vue&type=script&lang=js"
import style0 from "./EditProductModal.vue?vue&type=style&index=0&id=4ca17249&prod&lang=scss"
import style1 from "./EditProductModal.vue?vue&type=style&index=1&id=4ca17249&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports