// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_jGa0s{overflow:auto;overflow-y:auto;overflow-x:hidden;height:100vh;max-height:100%;max-height:-moz-available;max-height:-webkit-fill-available;max-height:fill-available}.layout_jGa0s::-webkit-scrollbar{width:0;outline:none;padding:0}.layout_jGa0s::-webkit-scrollbar-track{width:8px;background:rgba(0,0,0,0);padding:0 4px}.layout_jGa0s::-webkit-scrollbar-thumb{background:rgba(0,0,0,0);border-radius:2px;border-left:4px solid rgba(0,0,0,0);border-right:4px solid rgba(0,0,0,0)}@media(min-width: 768px){.layout_jGa0s{overflow:auto;overflow-x:hidden}.layout_jGa0s::-webkit-scrollbar{width:16px;outline:none;padding:0 4px}.layout_jGa0s::-webkit-scrollbar-track{width:8px;background:#232f3f;padding:0 4px}.layout_jGa0s::-webkit-scrollbar-thumb{background:#455566;border-radius:2px;border-left:4px solid #232f3f;border-right:4px solid #232f3f}}.layout__overlay_TnU5K{background-color:rgba(0,0,0,.66);position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:100;cursor:pointer}.layout__modalContainer_GoOq2{position:fixed;top:0;left:0;width:100vw;height:100%;z-index:998;overflow-y:auto;overflow-x:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "layout_jGa0s",
	"layout__overlay": "layout__overlay_TnU5K",
	"layout__modalContainer": "layout__modalContainer_GoOq2"
};
module.exports = ___CSS_LOADER_EXPORT___;
