class UtmService {
  constructor () {
    if (UtmService.instance) {
      return UtmService.instance
    }

    UtmService.instance = this

    return this
  }

  sendUtm (engine, utm) {
    return engine.post('/utm', utm)
  }
}

export default new UtmService()
