// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_QDGIN{width:100%;max-width:600px;padding-top:10px}.title_ae9w3{color:#fff;font-size:28px;line-height:36px;font-weight:700;max-width:80%}.text_z4Q55{color:#fff;margin:0 0 24px;font-size:16px;font-weight:400;line-height:24px}.body_skMhn{padding:40px 0}.label_DSiKk{margin:24px 0 12px;color:#90a4ae;font-size:12px;font-weight:500;line-height:12px;text-transform:uppercase}.button_HGIbP{width:280px;margin:24px 0 0}.selector_YPuQi{display:grid;grid-template-columns:1fr;grid-gap:16px}@media(min-width: 768px){.selector_YPuQi{grid-template-columns:1fr 1fr}}.selector__variant_7T1Rb{display:flex;align-items:center;justify-content:flex-start;background:#2b3848;border:2px solid #2b3848;border-radius:12px;padding:16px;color:#fff;font-size:14px;font-weight:500;line-height:18px;cursor:pointer}.selector__variantActive_Y2rcp{border:2px solid #1e88e5;cursor:auto}.selector__icon_RliHw{width:24px;height:24px;padding:2px;margin-right:8px}.selector__iconRound_yiajW{display:flex;align-items:center;justify-content:center;width:100%;height:100%;border-radius:50%;border:2px solid #556877}.selector__iconRoundActive_X\\+vis{border:2px solid #1e88e5}.selector__iconDot_HkEAk{width:12px;height:12px;border-radius:50%;background:#1e88e5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_QDGIN",
	"title": "title_ae9w3",
	"text": "text_z4Q55",
	"body": "body_skMhn",
	"label": "label_DSiKk",
	"button": "button_HGIbP",
	"selector": "selector_YPuQi",
	"selector__variant": "selector__variant_7T1Rb",
	"selector__variantActive": "selector__variantActive_Y2rcp",
	"selector__icon": "selector__icon_RliHw",
	"selector__iconRound": "selector__iconRound_yiajW",
	"selector__iconRoundActive": "selector__iconRoundActive_X+vis",
	"selector__iconDot": "selector__iconDot_HkEAk"
};
module.exports = ___CSS_LOADER_EXPORT___;
