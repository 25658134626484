<script>
import BaseSelect from '@/components/BaseSelect/BaseSelect.vue'
import NumberInput from '@/components/NumberInput/NumberInput.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import ImageUpload from '@/components/ImageUpload/ImageUpload'
import Tabs from '@/components/Tabs/Tabs.vue'
import StoreService from '@/services/StoreService'
import { LANGUAGES } from '@/utils/lang'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditProductModal',

  components: {
    Tabs,
    BaseSelect,
    NumberInput,
    ImageUpload,
    FormInput
  },

  props: {
    productId: {
      type: Number,
      required: true
    },

    updated: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      activeServiceType: 'game',
      price: (this.product?.min_price / 100).toFixed(2),
      amount: this.product?.quantity.toString(),
      description: LANGUAGES.map(l => ({
        lang: l.value,
        description: l.value === 'en' ? this.product?.description : this.product?.seo_data?.[l.value]?.description
      })).reduce((a, b) => ({
        ...a,
        [b.lang]: b.description
      }), {}),
      shortDescription: LANGUAGES.map(l => ({
        lang: l.value,
        description: l.value === 'en' ? this.product?.short_description : this.product?.seo_data?.[l.value]?.h1
      })).reduce((a, b) => ({
        ...a,
        [b.lang]: b.description
      }), {}),
      additionalData: this.product?.specifications?.reduce((a, b) => ({ ...a, [b.key]: b.value }), {}),
      updateInProgress: false,
      uploadedFile: undefined,
      autoData: this.product?.auto_messages,
      images: this.product?.images || [],
      activeLangIndex: 0,
      product: null,
      options: undefined,
      category: this.product?.application_category_id.toFixed(0)
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('marketplace', ['apps', 'currencies']),

    currentApp () {
      if (!this.apps) {
        return undefined
      }

      return this.apps.filter(app => app.categories.map(c => c.id).includes(this.product?.application_category_id))
        ?.map(app => ({ ...app, value: app.id.toString() }))
        ?.[0]
    },

    notLimited () {
      return +this.amount === 1111111
    },

    goodsCounter () {
      if (this.notLimited) {
        return '∞'
      }
      return this.autoData?.split(/(?:\r\n|\r|\n)/g)?.length
    },

    languages () {
      return this.isRu ? LANGUAGES.filter(l => l.value === 'ru') : LANGUAGES.filter(l => l.value !== 'ru')
    },

    currentAppName () {
      return this.currentApp?.title || '-'
    },

    isRu () {
      return this.$i18n.locale === 'ru'
    },

    rubCurrencyRate () {
      return this.currencies?.find(c => c.code === 'RUB')?.exchange_rate || 0
    },

    unlimited () {
      return this.product?.quantity === 1111111
    },

    activeLang () {
      return this.languages[this.activeLangIndex]
    },

    appCategories () {
      return this.currentApp?.categories?.map(category => ({
        title: category?.category_name,
        value: category?.id?.toFixed(0)
      }))
    },

    categoryName () {
      return this.appCategories?.find(c => +c.value === +this.category)?.title || '-'
    },

    variantsField () {
      return this.product?.fields?.find(f => f?.type === 'variants')
    },

    variantsFieldOptions () {
      return this.variantsField?.options
    },

    variantsFieldTitle () {
      return this.variantsField?.title
    },

    productOptions () {
      return this.product?.options
    },

    additionalFields () {
      return this.currentApp?.categories?.find(c => +c.id === +this.category)?.fields || []
    },

    customerPrice () {
      if (this.price === 0) {
        return '-'
      }

      return (Math.ceil((this.price * 1.05) * 100) / 100).toFixed(2)
    },

    canUpdate () {
      return this.description?.en && this.description?.en?.trim() !== '' && this.shortDescription?.en && this.shortDescription?.en?.trim() !== ''
    },

    productIsHidden () {
      return this.product?.hidden
    }
  },

  watch: {
    uploadedFile () {
      if (this.uploadedFile) {
        this.images.push(this.uploadedFile)
        this.uploadedFile = undefined
      }
    }
  },

  async mounted () {
    if (!this.apps) {
      this.fetchApps()
    }

    this.product = (await StoreService.getMarketplace(this.$axios, this.productId))?.data?.data

    this.price = (this.product?.min_price / 100).toFixed(2)
    this.amount = this.product?.quantity.toString()
    this.category = this.product?.application_category_id.toFixed(0)
    this.description = LANGUAGES.map(l => ({
      lang: l.value,
      description: l.value === 'en' ? this.product?.description : this.product?.seo_data?.[l.value]?.description
    })).reduce((a, b) => ({
      ...a,
      [b.lang]: b.description
    }), {})
    this.shortDescription = LANGUAGES.map(l => ({
      lang: l.value,
      description: l.value === 'en' ? this.product?.short_description : this.product?.seo_data?.[l.value]?.h1
    })).reduce((a, b) => ({
      ...a,
      [b.lang]: b.description
    }), {})
    this.additionalData = this.product?.specifications?.reduce((a, b) => ({ ...a, [b.key]: b.value }), {})
    this.images = this.product?.images || []
    this.autoData = this.product?.auto_messages

    const opts = this.productOptions
    const findFunc = title => po => po?.title?.startsWith(title)
    this.options = this.variantsFieldOptions?.map((o) => {
      const title = '[' + (o?.title || '') + ']'
      const product = opts?.find(findFunc(title))
      const autoData = product.auto_messages
      return {
        ...o,
        quantity: product.quantity,
        price: +((product.min_price / 100).toFixed(2)),
        auto_messages: autoData,
        product
      }
    })
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('marketplace', ['fetchApps']),

    selectorVariantStyles (type) {
      return {
        [this.$style.selector__variant]: true,
        [this.$style.selector__variantActive]: type === this.activeServiceType
      }
    },

    addImageClick () {
      this.$refs.imageInput?.click()
    },

    setAppCategory (event) {
      this.category = event
      this.additionalData = {}
    },

    roundStyles (type) {
      return {
        [this.$style.selector__iconRound]: true,
        [this.$style.selector__iconRoundActive]: type === this.activeServiceType
      }
    },

    enumToOptions (enumData) {
      return enumData.map(value => ({
        title: value,
        value
      }))
    },

    setActiveLang ({ index }) {
      this.activeLangIndex = index
    },

    async deleteProduct () {
      if (this.updateInProgress) {
        return
      }

      this.updateInProgress = true
      this.$nuxt.$loading.start()

      try {
        const { data } = await StoreService.deleteProduct(this.$axios, this.productId)
        this.$nuxt.$loading.finish()

        if (data?.data) {
          this.updated()
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'info',
              text: this.$t('success')
            }
          })
        }
      } catch (e) {
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('error'),
            type: 'error',
            text: this.$t('error')
          }
        })
      }
    },

    async recoverProduct () {
      if (this.updateInProgress) {
        return
      }

      this.updateInProgress = true
      this.$nuxt.$loading.start()

      try {
        const { data } = await StoreService.recoverProduct(this.$axios, this.productId)
        this.$nuxt.$loading.finish()

        if (data?.data) {
          this.updated()
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'info',
              text: this.$t('success')
            }
          })
        }
      } catch (e) {
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('error'),
            type: 'error',
            text: this.$t('error')
          }
        })
      }
    },

    inRub (price) {
      if (price === '') {
        return '...'
      }

      return Math.ceil(+price / (this.rubCurrencyRate * 0.98))
    },

    addVariant () {
      this.options.push({
        title: '',
        price: 0,
        isDefault: false,
        quantity: this.unlimited ? 1111111 : 1,
        id: 'new__' + new Date(),
        auto_messages: this.options?.[0]?.auto_messages ? '' : undefined
      })
    },

    deleteFromImages (imgUrl) {
      this.images = this.images.filter(img => img !== imgUrl)
    },

    async updateProduct () {
      if (!this.canUpdate || this.updateInProgress) {
        return
      }

      this.updateInProgress = true
      this.$nuxt.$loading.start()

      // eslint-disable-next-line camelcase
      const seo_data = JSON.parse(JSON.stringify(this.product?.seo_data || {}))

      for (const key of Object.keys(this.shortDescription)) {
        if (!seo_data[key]) {
          seo_data[key] = {
            h1: this.shortDescription[key],
            description: this.description[key] || ''
          }
        } else {
          seo_data[key] = {
            ...seo_data[key],
            h1: this.shortDescription[key],
            description: this.description[key] || ''
          }
        }
      }

      const product = {
        // id: this.productId,
        // min_price: +(this.price * 100).toFixed(0),
        // quantity: this.amount,
        application_category_id: +this.category,
        category: this.categoryName,
        images: this.images,
        description: this.description?.en,
        short_description: this.shortDescription?.en,
        specifications: this.additionalData ? Object.keys(this.additionalData).map(key => ({
          key,
          value: this.additionalData[key]
        })) : [],
        seo_data
      }

      let optionsObject

      if (!this.options) {
        product.min_price = +((+this.price * 100).toFixed(0))
        product.quantity = +this.amount

        if (this.product?.auto_messages) {
          product.auto_messages = this.autoData
        }
      } else {
        optionsObject = this.options.map((option) => {
          const optionObject = {
            id: option.product?.id || 0,
            min_price: option.price * 100,
            quantity: option.quantity,
            auto_messages: option.auto_messages
          }

          if (option.id.startsWith('new__')) {
            optionObject.title = option.title
          }

          return optionObject
        })
      }

      try {
        const { data } = await StoreService.updateMarketplaceProduct(this.$axios, this.product?.id, product, optionsObject)

        this.$nuxt.$loading.finish()

        if (data?.data) {
          this.updated()
          this.showModal({
            component: 'ModalMessage',
            data: {
              title: this.$t('success'),
              type: 'info',
              text: this.$t('success')
            }
          })
        }
      } catch (e) {
        console.log(e)
        this.$nuxt.$loading.finish()
        this.showModal({
          component: 'ModalMessage',
          data: {
            title: this.$t('error'),
            type: 'error',
            text: this.$t('error')
          }
        })
      }
    }
  }
}
</script>

<template>
  <div :class="$style.modal">
    <p :class="$style.title">
      {{ $t('buttonEdit') }}
    </p>

    <template v-if="product && apps">
      <p :class="$style.subtitle">
        {{ $t("itemImages") }}
      </p>
      <ImageUpload v-if="images?.length < 10" v-model="uploadedFile" short>
        <template v-slot:button>
          <div ref="imageInput">
            <Button is-primary>
              {{ $t("uploadImage") }}
            </Button>
          </div>
        </template>
      </ImageUpload>

      <div :class="$style.images">
        <div v-for="image in images" :key="image" :class="$style.imageContainer">
          <img
            :src="image"
            alt="img"
            :class="$style.image"
            @click="deleteFromImages(image)"
          >
        </div>
        <div v-for="i in (10 - (images?.length || 0))" :key="`image_${i}`" :class="$style.imageContainer">
          <img v-if="i === 1" :src="require('~/assets/img/icons/plus.svg')" alt="plus" @click="addImageClick" />
        </div>
      </div>
    </template>

    <p :class="$style.subtitle">
      {{ $t("generalInfoAboutService") }}
    </p>

    <template v-if="product && apps">
      <p :class="$style.label">
        {{ currentAppName }}
      </p>

      <p :class="$style.label">
        {{ $t('category') }}
      </p>

      <BaseSelect
        :value="category"
        :options="appCategories"
        :placeholder="$t('choose')"
        @input="setAppCategory($event)"
      />

      <div v-for="additionalField in additionalFields" :key="additionalField.title || additionalField.name">
        <p :class="$style.label">
          {{ $t(additionalField.title || additionalField.name) }}
        </p>

        <NumberInput
          v-if="additionalField.type === 'number'"
          v-model="additionalData[`${additionalField.title || additionalField.name}`]"
          :class="$style.fullWidth"
          :increment="1"
          :min="0"
          :fixed="0"
          full-width
        />
        <BaseSelect
          v-else-if="additionalField.enum"
          v-model="additionalData[`${additionalField.title || additionalField.name}`]"
          :options="enumToOptions(additionalField.enum)"
          :placeholder="$t('choose')"
        />
        <FormInput v-else v-model="additionalData[`${additionalField.title || additionalField.name}`]" />
      </div>
    </template>

    <template v-if="product && apps">
      <Tabs v-if="languages?.length > 1" :class="$style.tabs" :items="languages" :active-tab-index="activeLangIndex" @change="setActiveLang" />

      <p :class="$style.subtitle">
        {{ $t("shortDescription") }} <template v-if="!isRu">
          ({{ activeLang?.title }})
        </template>
      </p>

      <FormInput :key="`short__${activeLang?.value}`" v-model="shortDescription[activeLang?.value]" type="textarea" />

      <p :class="$style.subtitle">
        {{ $t("description") }} <template v-if="!isRu">
          ({{ activeLang?.title }})
        </template>
      </p>

      <FormInput :key="`full__${activeLang?.value}`" v-model="description[activeLang?.value]" type="textarea" />

      <p :class="$style.subtitle">
        {{ $t("details") }}
      </p>

      <!--      <div :class="$style.details">-->
      <!--        <div :class="$style.detail">-->
      <!--          <span :class="$style.detail__key">-->
      <!--            {{ $t(activeServiceType) }}-->
      <!--          </span>-->
      <!--          <span :class="$style.detail__value">-->
      <!--            {{ currentAppName }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div :class="$style.detail">-->
      <!--          <span :class="$style.detail__key">-->
      <!--            {{ $t('category') }}-->
      <!--          </span>-->
      <!--          <span :class="$style.detail__value">-->
      <!--            {{ categoryName }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div :class="$style.detail">-->
      <!--          <span :class="$style.detail__key">-->
      <!--            {{ $t('amount') }}-->
      <!--          </span>-->
      <!--          <span :class="$style.detail__value">-->
      <!--            {{ amount }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div :class="$style.detail">-->
      <!--          <span :class="$style.detail__key">-->
      <!--            {{ $t('price') }}-->
      <!--          </span>-->

      <!--          <Currency v-if="price > 0" type="money" real>-->
      <!--            <span :class="$style.detail__value">{{ price }}</span>-->
      <!--          </Currency>-->
      <!--          <span v-else :class="$style.detail__value">-</span>-->
      <!--        </div>-->
      <!--        <div :class="$style.detail">-->
      <!--          <span :class="$style.detail__key">-->
      <!--            {{ $t('priceForCustomer') }}-->
      <!--          </span>-->
      <!--          <span :class="$style.detail__value">-->
      <!--            {{ customerPrice }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->

      <div v-if="options">
        <p :class="$style.subtitle">
          {{ variantsFieldTitle }}
        </p>

        <div v-for="option in options" :key="option.id" :class="$style.option">
          <FormInput
            v-model="option.title"
            :class="$style.fullWidth"
            :placeholder="$t('optionName')"
            full-width
          />

          <div>
            <p :class="$style.label">
              {{ $t("price") }}
            </p>
            <NumberInput
              v-model="option.price"
              :class="$style.fullWidth"
              :increment="0.01"
              :fixed="2"
              real
              full-width
              currency="money"
            />
          </div>

          <div v-if="isRu">
            <p :class="$style.label">
              Цена в рублях:
            </p>
            <Currency type="money" size="24" is-reversed rub>
              <span :class="$style.subtitle">{{ inRub(option?.price) }}</span>
            </Currency>
          </div>

          <div>
            <p :class="$style.label">
              {{ $t("amount") }}
            </p>
            <FormInput
              v-if="unlimited"
              value="∞"
              :class="$style.fullWidth"
              full-width
              is-view-mode
            />
            <FormInput
              v-if="options[0].auto_messages || option.auto_messages"
              :value="option.auto_messages?.split(/(?:\r\n|\r|\n)/g).length"
              :class="$style.fullWidth"
              full-width
              is-view-mode
            />
            <NumberInput
              v-else
              v-model="option.quantity"
              :class="$style.fullWidth"
              :increment="1"
              :fixed="0"
              full-width
            />
          </div>

          <template v-if="options[0].auto_messages || option.auto_messages">
            <p :class="$style.subtitle">
              {{ $t("goods") }}
            </p>

            <FormInput v-model="option.auto_messages" type="textarea" />
          </template>
        </div>

        <div>
          <Button is-secondary @click="addVariant">
            {{ $t("addVariant") }}
          </Button>
        </div>
      </div>

      <template v-else>
        <div :class="$style.twoColumn">
          <div>
            <div>
              <p :class="$style.label">
                {{ $t('price') }}
              </p>

              <NumberInput
                v-model="price"
                :class="$style.fullWidth"
                :increment="0.01"
                :fixed="2"
                real
                full-width
                currency="money"
              />
            </div>
            <div v-if="isRu">
              <p :class="$style.label">
                Цена в рублях:
              </p>
              <Currency type="money" size="24" is-reversed rub>
                <span :class="$style.subtitle">{{ inRub(price) }}</span>
              </Currency>
            </div>
          </div>
          <div>
            <div>
              <p :class="$style.label">
                {{ $t('amount') }}
              </p>

              <div v-if="product?.auto_messages || notLimited" :class="$style.fieldBlocked">
                {{ goodsCounter }}
              </div>

              <NumberInput
                v-else
                v-model="amount"
                :class="$style.fullWidth"
                :increment="1"
                :max="9000000"
                :min="1"
                :fixed="0"
                full-width
              />
            </div>
            <div></div>
          </div>
        </div>

        <template v-if="product?.auto_messages">
          <p :class="$style.subtitle">
            {{ $t("goods") }}
          </p>

          <FormInput v-model="autoData" type="textarea" />
        </template>
      </template>

      <Button is-primary :class="$style.save" :disabled="!canUpdate" @click="updateProduct">
        {{ $t('buttonSave') }}
      </Button>

      <Button v-if="!productIsHidden" is-secondary :class="$style.save" @click="deleteProduct">
        {{ $t('hide') }}
      </Button>

      <Button v-else is-secondary :class="$style.save" @click="recoverProduct">
        {{ $t('recover') }}
      </Button>
    </template>
  </div>
</template>

<style lang="scss">
.modal--editproductmodal {
  @include scroll;

  width: 100%;
  max-width: 600px !important;
  max-height: 95vh;
  overflow: auto;
}
</style>

<style lang="scss" module>
.modal {
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.title {
  color: $white;
  font-size: 32px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 32px;
}

.subtitle {
  margin: 24px 0;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.option {
  padding: 16px;
  background: $blue-grey80;
  color: $white;
  margin: 8px 0;
  border-radius: 8px;
}

.label {
  margin: 24px 0 8px;
  color: $blue-grey10;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-transform: uppercase;
}

.selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  &__variant {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $blue-grey50;
    border: 2px solid $blue-grey50;
    border-radius: 12px;
    padding: 16px;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &Active {
      border: 2px solid $primary60;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-right: 8px;

    &Round {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid $blue-grey20;

      &Active {
        border: 2px solid $primary60;
      }
    }

    &Dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $primary60;
    }
  }
}

.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  align-items: start;
}

.fullWidth {
  width: 100%;
}

.details {
  display: grid;
  padding: 24px 0;
  grid-gap: 12px;
  grid-template-columns: 1fr;
  border-top: 1px solid $blue-grey40;
  border-bottom: 1px solid $blue-grey40;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__key {
    color: $blue-grey10;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__value {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.save {
  width: 100% !important;
  margin-top: 40px;

  &:disabled {
    opacity: 0.3;
  }
}

.fieldBlocked {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  width: 100%;
  padding: 0 16px;
  border-radius: 8px;
  background: $blue-grey70;
  color: $blue-grey10;
}

.tabs {
  margin: 20px 0 0;
  height: 60px;
  background: $blue-grey60;
  padding: 12px 24px;
  border-radius: 8px;
  width: max-content;
}

.images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  gap: 4px;
  margin: 12px 0;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;

  &Container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    padding: 8px;
    background: $blue-grey40;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &Add {
    width: 75%;
  }
}
</style>
